import React, { useEffect, useState, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import PaymentForm from '../../Forms/FormPayment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomDatePicker from '../../DatePicker';
import ApiService from '../../../../services/ApiService';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as bootstrap from 'bootstrap';
import { MultiSelect } from "react-multi-select-component";
import '../../style.css';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { differenceInYears } from 'date-fns';
import FormLoginAppointment from '../../Forms/FormLoginAppointment.js';
import countrycodes from '../../areacodes';

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);

const ModalCreate = (props) => {

    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState(1914);

    // Obtener la fecha de hoy
    const fechaHoy = new Date();

    // Restar 18 años
    const fecha18AnosAtras = new Date(fechaHoy);
    fecha18AnosAtras.setFullYear(fechaHoy.getFullYear() - 18);

    // Obtener el año como número
    const año18AnosAtras = fecha18AnosAtras.getFullYear();
    const maxfechaHoy = fechaHoy.getFullYear()
    // Mostrar el año
    console.log('Año 18 años atrás:', año18AnosAtras);


    const handleMonthChange = (e) => {
        const value = parseInt(e.target.value);
        // Validación de mes (en un rango de 1 a 12)
        if (value >= 1 && value <= 12) {
            setMonth(value);
            // Reiniciar el día si no es válido para el mes seleccionado
            if (day !== '' && (parseInt(day) < 1 || parseInt(day) > daysInMonth(value, year))) {
                setDay('');
            }
        } else {
            setMonth('');
        }
    };

    const handleDayChange = (e) => {
        const value = parseInt(e.target.value);
        // Validación de día (en un rango válido para el mes y el año)
        if (value >= 1 && value <= daysInMonth(month, year)) {
            setDay(value);
        } else {
            setDay('');
        }
    };

    const handleYearChange = (e) => {
        const value = parseInt(e.target.value);
        // Validación de año (ej. asegurarse que sea un año válido)
        setYear(value);
        // Reiniciar el día si no es válido para el mes y el año seleccionado
        if (day !== '' && (parseInt(day) < 1 || parseInt(day) > daysInMonth(month, value))) {
            setDay('');
        }
    };

    // Función para obtener el número de días en un mes específico de un año específico
    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };





    const modalRef = useRef(null);

    const { setHeader, dataDoctor, show, onHide, selectType} = props;

   
    
    const [selectedOption, setSelectedOption] = useState(selectType==='kids'?'client':'patient');
    const [selectedOptionLogin, setSelectedOptionLogin] = useState('no');

    const navigate = useNavigate();

    const [service, setService] = useState([]);

    

    const [options3, setOptions3] = useState([]);


    const [stripeToken, setStripeToken] = useState(null);

    const [makeAppointment, setMakeAppointment] = useState(false);

    const [errorMessage, setErrorMessage] = useState();

    const [validationErrors, setValidationErrors] = useState({});
    const [fechaDeHoy, setFechaDeHoy] = useState('');

    const [selectedServices, setSelectedServices] = useState([]);

    const [total, setTotal] = useState(0);

    const [terms, setTerms] = useState(false);
    const [terms2, setTerms2] = useState(false);

    const [spinner, setSpinner] = useState(false);

    const [registeredPatient, setRegisteredPatient] = useState(false);



    const [arrayCountries, setArrayCountries] = useState([]);

    const [arrayCities, setArrayCities] = useState([]);     // Estado local para almacenar la lista de ciudades
    const [nameCountry, setNameCountry] = useState();
    
    const [showPassword, setShowPassword] = useState(false);

    const [showPassword2, setShowPassword2] = useState(false);
    
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    useEffect(() => {

        getAllCountries();


    }, []);

    const getAllCountries = () => {
        ApiService.getAllCountries()
          .then(response => {
          
            setArrayCountries(response.data);
            
     
          })
          .catch(e => {
            console.log(e);
          });
    };


    /*
    const getAllCountries = async () => {
        const url = 'https://secure.geonames.org/countryInfoJSON?lang=es&username=alemaravilla&style=full';
    
        try {
            const response = await fetch(url);
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
    
           
    
            if (data.geonames) {
                const countriesData = data.geonames;
                setArrayCountries(countriesData);
            } else {
                console.warn('No se encontraron datos de países en la respuesta.');
            }
            
        } catch (error) {
            console.error('Error al obtener los países:', error);
        }
    };*/


    const handleCountryChange = (event) => {
        
        
        const selectedCountry = event.target.value;
        
        const parsedValue = JSON.parse(selectedCountry); 

        setNameCountry(parsedValue.countryName);

        setFormData({
            ...formData,
            country: parsedValue.countryName,
        });
       
        ApiService.getCitiesByGeonameId(parsedValue.geonameId)
        .then(response => {

            setArrayCities(response.data);
               
        })
        .catch(e => {
          console.log(e);
        });

    };

    const handleCityChange = (event) =>{
        
        setFormData({
            ...formData,
            city: event.target.value,
        });
       
        
    }


    const [validationRules, setValidationRules]=useState(

        [
            {
                field: 'firstName',
                errorMessage: 'First Name is required.',
                isValid: (value) => value.trim() !== '',
            },
            {
                field: 'lastName',
                errorMessage: 'Last Name is required.',
                isValid: (value) => value.trim() !== '',
            },
           
            {
                field: 'date_range_start',
                errorMessage: 'Date start is required.',
                isValid: (value) => value !== null,
            },
            {
                field: 'date_range_end',
                errorMessage: 'Date end is required.',
                isValid: (value) => value !== null,
            },
            {
                field: 'scheduled_datetime',
                errorMessage: 'Scheduled Datetime is required.',
                isValid: (value) => value !== null,
            },
            {
                field: 'service_id',
                errorMessage: 'Service is required.',
                isValid: (value) => selectedOption === 'patient' ? (value !== '') : (true),
            },
            {
                field: 'email',
                errorMessage: 'Email is required.',
                isValid: (value) => value !== '',
            },
            {
                field: 'email',
                errorMessage: 'Invalid email address.',
                isValid: (value) => isValidEmail(value),
            },
            {
                field: 'confirmemail',
                errorMessage: 'Confirm Email is required.',
                isValid: (value) => value !== '',
            },
            {
                field: 'confirmemail',
                errorMessage: 'Invalid confirm email address.',
                isValid: (value) => isValidEmail(value),
            },
            {
                field: 'cell_phone_number',
                errorMessage: 'phone is required.',
                isValid: (value) => value !== '',
            },
            {
                field: 'city',
                errorMessage: 'city is required.',
                isValid: (value) => value !== '',
            },
            {
                field: 'country',
                errorMessage: 'country is required.',
                isValid: (value) => value !== '',
            },
            {
                field: 'password',
                errorMessage: 'Password is required.',
                isValid: (value) => value !== '',
            },
            {
                field: 'password',
                errorMessage: 'Password must be at least 8 characters long.',
                isValid: (value) => value.length >= 8,
            },
            {
                field: 'birthdate',
                errorMessage: 'Birthdate is required.',
                isValid: (value) => value !== null,
            },
            {
                field: 'birthdate',
                errorMessage: 'you have to be of legal age',
                isValid: (value) => differenceInYears(new Date(), new Date(value)) > 18,
            },
            {
                field: 'birthdate',
                 errorMessage: 'The maximum age allowed is 110 years.',
                isValid: (value) => differenceInYears(new Date(), new Date(value)) < 110,
            }
    
        ]

        
    );


    
    const [formData, setFormData] = useState({
        user_id:null,
        patient_id:null,
        firstName: '',
        lastName: '',
        email: '',
        birthdate: null,
        password: '',
        confirmpassword: '',
        scheduled_datetime: null,
        service_id: [],
        date_range_start: null,
        date_range_end: null,
        comments:'',
        country:'',
        city:'',
        cell_phone_number:'',
        confirmemail:''

    });


    const [additionalFields, setAdditionalFields] = useState([
        {

            user_id:null,
            client_id:null,
            first_name: '',
            last_name: '',
            date_of_birth: null,
            view_birth: null,
            services: [],
            services_2: [],
        },
    ]);

    const [birthdays, setBirthdays] = useState([
        {
            month:'', 
            day:'', 
            year:'', 

        },
    ]);


    const handleMonthChange2 = (event, index) => {
        
        const value = parseInt(event.target.value);
    

        if (value >= 1 && value <= 12) {
            
            const updatedBirthdays = [...birthdays];
            
            updatedBirthdays[index] = {
                ...updatedBirthdays[index],
                month: value,
            };
            
            setBirthdays(updatedBirthdays);
            

            if (birthdays[index].day !== '' && (parseInt(birthdays[index].day) < 1 || parseInt(birthdays[index].day) > daysInMonth(value, birthdays[index].year))) {
               

                const updatedBirthdays = [...birthdays];

                updatedBirthdays[index] = {
                    ...updatedBirthdays[index],
                    day: '', 
                };
            
                setBirthdays(updatedBirthdays);
            }
        } else {
            

            const updatedBirthdays = [...birthdays];
                
            updatedBirthdays[index] = {
                ...updatedBirthdays[index],
                month: '', 
            };
            
            setBirthdays(updatedBirthdays);
        }


    
    };



    const handleDayChange2 = (event, index) => {
        
        const value = parseInt(event.target.value);
        
        
        if (value >= 1 && value <= daysInMonth(birthdays[index].month, birthdays[index].year)) {

            const updatedBirthdays = [...birthdays];
            
            updatedBirthdays[index] = {
                ...updatedBirthdays[index],
                day: value,
            };
            
            setBirthdays(updatedBirthdays);

        } else {
            
            
            const updatedBirthdays = [...birthdays];
                
            updatedBirthdays[index] = {
                ...updatedBirthdays[index],
                day: '', 
            };
            
            setBirthdays(updatedBirthdays);
        }
    
        
    };
    
    const handleYearChange2 = (event, index) => {
        
        
        const value = parseInt(event.target.value);
      
      
       
        const updatedBirthdays = [...birthdays];
            
        updatedBirthdays[index] = {
            ...updatedBirthdays[index],
            year: value,
        };
        
        setBirthdays(updatedBirthdays);
       
      
      
        if (birthdays[index].day !== '' && (parseInt(birthdays[index].day) < 1 || parseInt(birthdays[index].day) > daysInMonth(birthdays[index].month, value))) {
        

            
            const updatedBirthdays = [...birthdays];

            updatedBirthdays[index] = {
                ...updatedBirthdays[index],
                day: '', 
            };
        
            setBirthdays(updatedBirthdays);
        }
        
        
    };
    

    
    
    useEffect(() => {


    if(birthdays.length > 0){   
        
        additionalFields.map((field, index) => {


            if (birthdays[index].year && birthdays[index].month && birthdays[index].day) {


                const selectedDate = new Date(birthdays[index].year, birthdays[index].month - 1, birthdays[index].day); 
    
    
                const updatedAdditionalFields = [...additionalFields];
    
                updatedAdditionalFields[index] = {
                    ...updatedAdditionalFields[index],
                    date_of_birth: format(selectedDate, 'yyyy/MM/dd'), 
                };
            
                setAdditionalFields(updatedAdditionalFields);
    
            }else{
    
    
                const updatedAdditionalFields = [...additionalFields];
    
                updatedAdditionalFields[index] = {
                    ...updatedAdditionalFields[index],
                    date_of_birth: null, 
                };
            
                setAdditionalFields(updatedAdditionalFields);
        

    
            }

       })

    }
    
    }, [birthdays]);










    /*
    const  registerBirthday = (index) =>{


        if (birthdays[index].year && birthdays[index].month && birthdays[index].day) {


            const selectedDate = new Date(birthdays[index].year, birthdays[index].month - 1, birthdays[index].day); 


            const updatedAdditionalFields = [...additionalFields];

            updatedAdditionalFields[index] = {
                ...updatedAdditionalFields[index],
                date_of_birth: format(selectedDate, 'yyyy/MM/dd'), 
            };
        
            setAdditionalFields(updatedAdditionalFields);

        }else{


            const updatedAdditionalFields = [...additionalFields];

            updatedAdditionalFields[index] = {
                ...updatedAdditionalFields[index],
                date_of_birth: null, 
            };
        
            setAdditionalFields(updatedAdditionalFields);





        }

    }*/

   


    useEffect(() => {


        if (year && month && day) {
            // Crear un objeto Date con los valores seleccionados
            const selectedDate = new Date(year, month - 1, day); // Meses en JavaScript van de 0 a 11
           
            setFormData({
                ...formData,
                birthdate: selectedDate,
            });
           
           
        }else{

            setFormData({
                ...formData,
                birthdate:null,
            });
            
        }

    }, [month, day, year]);




    const addField = () => {

 
    
        setBirthdays([...birthdays, {month:'', day:'', year:''}]);

        setAdditionalFields([...additionalFields, { first_name: '', last_name: '', date_of_birth: null, view_birth: null, services: [], services_2: [] }]);
    };

    const removeField = (index) => {

        const updatedData = [...additionalFields];
        updatedData.splice(index, 1);
        setAdditionalFields(updatedData);

        if(registeredPatient===false){

            const updateBirthdays = [...birthdays];
            updateBirthdays.slice(index,1);
            setBirthdays(updateBirthdays);

        }
        


    };

    const [selectedOptions, setSelectedOptions] = useState([]);



    useEffect(() => {
        setSelectedOptions([]);

    }, [selectedOption]);


    const handleInputChange2 = (event, index, fieldName) => {



        if (fieldName === "date_of_birth") {


            const updatedFormData = [...additionalFields];
            updatedFormData[index][fieldName] = format(event, 'yyyy/MM/dd');
            updatedFormData[index]['view_birth'] = event;
            setAdditionalFields(updatedFormData)





        } else {

            if (fieldName === "services") {

                const selectedIds = event.map(option => option.value);
                const updatedFormData = [...additionalFields];
                updatedFormData[index][fieldName] = selectedIds;
                updatedFormData[index]["services_2"] = event;
                setAdditionalFields(updatedFormData);
                const selectedIds2 = event.map(option => option.value);
                setSelectedOptions(selectedIds2);


            } else {
                const updatedFormData = [...additionalFields];
                updatedFormData[index][fieldName] = event.target.value;
                setAdditionalFields(updatedFormData);


            }


        }


    };


    const handleRadioChangeLogin = (event) => {
        
        setSelectedOptionLogin(event.target.value);
    };


    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);

        handleModalHide();
    };






    useEffect(() => {

        const hoy = new Date();
        const formattedDate = format(hoy, 'yyyy/MM/dd');


        setFechaDeHoy(formattedDate);
    }, []);





    const handleSelectedChange = (e) => {
        setSelectedOptions(e);
        const selectedIds = e.map(option => option.value);
        setFormData({ ...formData, service_id: selectedIds, });

    };





    const handleInputChange = (e) => {


      
       

        if (e.target.name === 'birthdate') {

    
            setFormData({
                ...formData,
                birthdate: e.target.value,
            });


        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };


    useEffect(() => {

        handleModalHide();

        
        if(selectedOptionLogin==='yes'){
            

            if(  selectedOption === 'patient'){
                setValidationRules([]);
            setValidationRules([
                {
                    field: 'firstName',
                    errorMessage: 'First Name is required.',
                    isValid: (value) => value.trim() !== '',
                },
                {
                    field: 'lastName',
                    errorMessage: 'Last Name is required.',
                    isValid: (value) => value.trim() !== '',
                },
               
                {
                    field: 'date_range_start',
                    errorMessage: 'Date start is required.',
                    isValid: (value) => value !== null,
                },
                {
                    field: 'date_range_end',
                    errorMessage: 'Date end is required.',
                    isValid: (value) => value !== null,
                },
                {
                    field: 'scheduled_datetime',
                    errorMessage: 'Scheduled Datetime is required.',
                    isValid: (value) => value !== null,
                },
                {
                    field: 'service_id',
                    errorMessage: 'Service is required.',
                    isValid: (value) => selectedOption === 'patient' ? (value !== '') : (true),
                }
            ]);

            }else{
                setValidationRules([]);
            setValidationRules([
                {
                    field: 'firstName',
                    errorMessage: 'First Name is required.',
                    isValid: (value) => value.trim() !== '',
                },
                {
                    field: 'lastName',
                    errorMessage: 'Last Name is required.',
                    isValid: (value) => value.trim() !== '',
                },
               
                {
                    field: 'date_range_start',
                    errorMessage: 'Date start is required.',
                    isValid: (value) => value !== null,
                },
                {
                    field: 'date_range_end',
                    errorMessage: 'Date end is required.',
                    isValid: (value) => value !== null,
                },
                {
                    field: 'scheduled_datetime',
                    errorMessage: 'Scheduled Datetime is required.',
                    isValid: (value) => value !== null,
                }
            ]);

            }



        }else{
            if(  selectedOption === 'patient'){
                setValidationRules([]);
                setValidationRules([
                    {
                        field: 'firstName',
                        errorMessage: 'First Name is required.',
                        isValid: (value) => value.trim() !== '',
                    },
                    {
                        field: 'lastName',
                        errorMessage: 'Last Name is required.',
                        isValid: (value) => value.trim() !== '',
                    },
                    {
                        field: 'date_range_start',
                        errorMessage: 'Date start is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'date_range_end',
                        errorMessage: 'Date end is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'scheduled_datetime',
                        errorMessage: 'Scheduled Datetime is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'service_id',
                        errorMessage: 'Service is required.',
                        isValid: (value) => selectedOption === 'patient' ? (value !== '') : (true),
                    },
                    {
                        field: 'email',
                        errorMessage: 'Email is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'email',
                        errorMessage: 'Invalid email address.',
                        isValid: (value) => isValidEmail(value),
                    },
                    {
                        field: 'confirmemail',
                        errorMessage: 'confirmemail is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'confirmemail',
                        errorMessage: 'Invalid Confirm Email address.',
                        isValid: (value) => isValidEmail(value),
                    },
                    {
                        field: 'cell_phone_number',
                        errorMessage: 'phone is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'city',
                        errorMessage: 'city is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'country',
                        errorMessage: 'country is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'password',
                        errorMessage: 'Password is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'password',
                        errorMessage: 'Password must be at least 8 characters long.',
                        isValid: (value) => value.length >= 8,
                    },
                    {
                        field: 'birthdate',
                        errorMessage: 'Birthdate is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'birthdate',
                        errorMessage: 'you have to be of legal age',
                        isValid: (value) => differenceInYears(new Date(), new Date(value)) > 18,
                    },
                    {
                        field: 'birthdate',
                         errorMessage: 'The maximum age allowed is 110 years.',
                        isValid: (value) => differenceInYears(new Date(), new Date(value)) < 110,
                    }          
                ]);

            }else{

                setValidationRules([]);
                setValidationRules([
                    {
                        field: 'firstName',
                        errorMessage: 'First Name is required.',
                        isValid: (value) => value.trim() !== '',
                    },
                    {
                        field: 'lastName',
                        errorMessage: 'Last Name is required.',
                        isValid: (value) => value.trim() !== '',
                    },
                
                    {
                        field: 'date_range_start',
                        errorMessage: 'Date start is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'date_range_end',
                        errorMessage: 'Date end is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'scheduled_datetime',
                        errorMessage: 'Scheduled Datetime is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'email',
                        errorMessage: 'Email is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'email',
                        errorMessage: 'Invalid email address.',
                        isValid: (value) => isValidEmail(value),
                    },
                    {
                        field: 'confirmemail',
                        errorMessage: 'confirmemail is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'confirmemail',
                        errorMessage: 'Invalid Confirm Email address.',
                        isValid: (value) => isValidEmail(value),
                    },
                    {
                        field: 'cell_phone_number',
                        errorMessage: 'phone is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'city',
                        errorMessage: 'city is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'country',
                        errorMessage: 'country is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'password',
                        errorMessage: 'Password is required.',
                        isValid: (value) => value !== '',
                    },
                    {
                        field: 'password',
                        errorMessage: 'Password must be at least 8 characters long.',
                        isValid: (value) => value.length >= 8,
                    },
                    {
                        field: 'birthdate',
                        errorMessage: 'Birthdate is required.',
                        isValid: (value) => value !== null,
                    },
                    {
                        field: 'birthdate',
                        errorMessage: 'you have to be of legal age',
                        isValid: (value) => differenceInYears(new Date(), new Date(value)) > 18,
                    },
                    {
                        field: 'birthdate',
                         errorMessage: 'The maximum age allowed is 110 years.',
                        isValid: (value) => differenceInYears(new Date(), new Date(value)) < 110,
                    }

                ]);

                

            }  
            

        }

      

    }, [selectedOptionLogin, selectedOption]);
   


    const differenceInFullYears = (date1, date2) => {
   
        let yearsDiff =date1.getFullYear() - date2.getFullYear();
    
    
        return yearsDiff;
    }



    const isValidEmail = (email) => {
        // Expresión regular para validar un correo electrónico
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    };


    const validateForm = () => {
        const errors = {};
        
       
        validationRules.forEach((rule) => {
          
       
            if (rule.field === 'email') {
                if (!isValidEmail(formData.email)) {
                    errors[rule.field] = 'Invalid email address';
                }
            } else if (rule.field === 'customDatePicker') {
                if (!rule.isValid(formData[rule.field])) {
                    errors.customDatePicker = rule.errorMessage;
                }
            } else {
                if(!rule.isValid(formData[rule.field])) {
                    errors[rule.field] = rule.errorMessage;
                }
            }

            
 

           
        });

        
        if( formData.password != '' && selectedOptionLogin==='no'){
           
            if(formData.confirmpassword != formData.password){
             
              errors["confirmpassword"] = "Passwords do not match";

            }

        }

        if( formData.email != '' && selectedOptionLogin==='no'){
           
            if(formData.confirmemail != formData.email){
             
              errors["confirmemail"] = "Emails do not match";

            }

        }


        if(formData.date_range_start && formData.date_range_end && formData.scheduled_datetime ){


            const scheduled_datetime = format(formData.scheduled_datetime, 'yyyy/MM/dd');
            const date_range_start = format(formData.date_range_start, 'yyyy/MM/dd');
            const date_range_end = format(formData.date_range_end, 'yyyy/MM/dd');
            
            if(! (scheduled_datetime >= date_range_start && scheduled_datetime <= date_range_end)){
                
                errors["scheduled_datetime"] = "The day and time must fall within the date range specified for the appointment with the specialist.";
            }
          

        }
      
        setValidationErrors(errors);
       
        return Object.keys(errors).length === 0;
    };



    const [validationErrors2, setValidationErrors2] = useState({});

    const validateForm2 = () => {
        let errors = {};

        additionalFields.forEach((field, index) => {
            // Validar el campo de nombre
            if (!field.first_name) {
                errors[`firstName-${index}`] = "First name is required";
            }

            // Validar el campo de apellido
            if (!field.last_name) {
                errors[`lastName-${index}`] = "Last name is required";
            }

            // Validar el campo de fecha de nacimiento
            if (!field.date_of_birth) {
                errors[`birthdate-${index}`] = "Birthdate is required";
            }

           
        
            if (differenceInYears(new Date(), new Date(field.date_of_birth)) > 110) {


                errors[`birthdate-${index}`] = "The maximum age allowed is 110 years.";
            }


           
            
            if (differenceInFullYears(new Date(), new Date(field.date_of_birth)) < 0) {

                errors[`birthdate-${index}`] = "Out of range.";
            }


            if (field.services && field.services.length === 0) {
                errors[`services-${index}`] = "Services is required";
            }


        });

    
        setValidationErrors2(errors);


       
        // Devolver true si no hay errores, false en caso contrario
        return Object.keys(errors).length === 0;
    };




    const handleModalHide = () => {

        
        setAdditionalFields([
            {
                first_name: '',
                last_name: '',
                date_of_birth: null,
                view_birth: null,
                services: [],
                services_2: [],
            },
        ]);



        setErrorMessage();
        setMakeAppointment(false);
        setStripeToken(null);
        setService({});
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            birthdate: null,
            password: '',
            confirmpassword: '',
            scheduled_datetime: null,
            service_id: '',
            date_range_start: null,
            date_range_end: null,
            virtual_call: false,
            conditions: false,
            comments:'',
            country:'',
            city:'',
            cell_phone_number:'',
        });

        //setHeader(false);
        setValidationErrors({});
        setValidationErrors2({});

        setSpinner(false);

        setRegisteredPatient(false);



    };




    const handleChange3 = (option) => {
        const serviceId = Number(option);
        const filteredService = dataDoctor.services.filter(
            (service) => service.id === serviceId
        );


        setFormData({
            ...formData,
            service_id: Number(option),
        });
        setService(filteredService);

    };



    const handleCheckboxChange3 = (e) => {


        setTerms(e.target.checked)

    };

    const handleCheckboxChange4 = (e) => {


        setTerms2(e.target.checked)

    };




    const handleCheckboxChange = (e) => {

        setFormData({
            ...formData,
            virtual_call: e.target.checked,
        });

    };


    const handleCheckboxChange2 = (e) => {
        setFormData({
            ...formData,
            virtual_call: e.target.checked,
        });

    };



    const handleDateChange = (dates) => {



        const [start, end] = dates;


        setFormData({
            ...formData,
            date_range_start: start,
            date_range_end: end,
        });




    };

    const handleDateChange2 = (date) => {

        setFormData({
            ...formData,
            scheduled_datetime: date,
        });

    };




    const callApi = (elements, miObjeto) => {
    

        if(!terms2){
        
            setSpinner(false);

            Swal.fire({
                icon: "error",
                text: "Please accept aware of the Cancellation Policy!",
              });
              return;
        }


        if(!terms){
        
            setSpinner(false);

            Swal.fire({
                icon: "error",
                text: "Please accept aware of the Cancellation Policy!",
              });
              return;
        }

        
    
        ApiService.postAppointments(miObjeto)
            .then(response => {

        

                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });


                if (elements) {
                    const cardElement = elements.getElement(CardElement);
                    cardElement.clear();
                }

                //setSpinner(false);
                
                onHide();
                handleModalHide();




            })
            .catch(e => {

                setSpinner(false);

                if(e.data.data.email){

                    Swal.fire({
                        icon: 'error',
                        title: e.response.data.message,
                        text: e.data.data.email,
    
                    })
                }else{

                    Swal.fire({
                        icon: 'error',
                        title: e.response.data.message,
                        text: e.response.data.error,
    
                    })

                }
            
                


            });


    }



    const callApi2 = (elements, miObjeto) => {

        

        if(!terms2){
        
            setSpinner(false);

            Swal.fire({
                icon: "error",
                text: "Please accept aware of the Cancellation Policy!",
              });
              return;
        }


        if(!terms){
    
           setSpinner(false);

            Swal.fire({
                icon: "error",
                text: "Please accept aware of the Cancellation Policy!",
              });
              return;
        }


    
        
        ApiService.postAppointments2(miObjeto)
            .then(response => {

                

                
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });


                if (elements) {
                    const cardElement = elements.getElement(CardElement);
                    cardElement.clear();
                }


                //setSpinner(false);
                onHide();
                handleModalHide();




            })
            .catch(e => {

                setSpinner(false);
                
                if(e.data.data.email){

                    Swal.fire({
                        icon: 'error',
                        title: e.response.data.message,
                        text: e.data.data.email,
    
                    })
                }else{

                    Swal.fire({
                        icon: 'error',
                        title: e.response.data.message,
                        text: e.response.data.error,
    
                    })

                }
            

            });


    }

    const handleSubmit = (elements, tokenId) => {

            



            if (selectedOption === 'patient') {
           
            
          

            if (validateForm()) {


                const miObjeto = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    date_of_birth: format(formData.birthdate, 'yyyy/MM/dd'),
                    email: formData.email,
                    doctor_id: dataDoctor.id,
                    password: formData.password,
                    password_confirmation: formData.confirmpassword,
                    stripeToken: tokenId,
                    services: formData.service_id,
                    date_range_start: format(formData.date_range_start, 'yyyy/MM/dd'),
                    date_range_end: format(formData.date_range_end, 'yyyy/MM/dd'),
                    virtual_call: dataDoctor.accepts_phone_appointments === 'yes' || dataDoctor.accepts_phone_appointments === 'both' ? (1) : (0),
                    request_date: fechaDeHoy,
                    scheduled_datetime: format(formData.scheduled_datetime, 'yyyy/MM/dd HH:mm'),
                    original_cost: total,
                    final_cost: total,
                    amount_paid: total,
                    type: selectedOption,
                    patients: [{date_of_birth:format(formData.birthdate, 'yyyy/MM/dd'), first_name:formData.firstName, last_name: formData.lastName, services:formData.service_id}],
                    email_doctor: dataDoctor.user.email,
                    user_id:formData.user_id,
                    patient_id:formData.patient_id,
                    comments:formData.comments,
                    cell_phone_number:countrycodes[nameCountry]?(`+${countrycodes[nameCountry]} ${formData.cell_phone_number} `):(formData.cell_phone_number),
                    city:formData.city,
                    country:formData.country

                };

                
           
                setSpinner(true);

                
                if(registeredPatient===true){

                    callApi2(elements, miObjeto);
                }else{

                   
                    callApi(elements, miObjeto);
                }
                

                
            }




        } else {

  
            if (validateForm() && validateForm2()) {
              
                let miObjeto = {};


                if(registeredPatient === true){
                    
                    miObjeto = {
                        first_name: formData.firstName,
                        last_name: formData.lastName,
                        email: formData.email,
                        doctor_id: dataDoctor.id,
                        password: formData.password,
                        password_confirmation: formData.confirmpassword,
                        stripeToken: tokenId,
                        services: formData.service_id,
                        date_range_start: format(formData.date_range_start, 'yyyy/MM/dd'),
                        date_range_end: format(formData.date_range_end, 'yyyy/MM/dd'),
                        virtual_call: dataDoctor.accepts_phone_appointments === 'yes' || dataDoctor.accepts_phone_appointments === 'both' ? (1) : (0),
                        request_date: fechaDeHoy,
                        scheduled_datetime: format(formData.scheduled_datetime, 'yyyy/MM/dd HH:mm'),
                        original_cost: total,
                        final_cost: total,
                        amount_paid: total,
                        type: selectedOption,
                        patients: additionalFields,
                        email_doctor: dataDoctor.user.email,
                        comments:formData.comments,
                        cell_phone_number:countrycodes[nameCountry]?(`+${countrycodes[nameCountry]} ${formData.cell_phone_number} `):(formData.cell_phone_number),
                        city:formData.city,
                        country:formData.country,
                        user_id:formData.user_id,
                    };

                }else{
                  
                    miObjeto = {
                        first_name: formData.firstName,
                        last_name: formData.lastName,
                        date_of_birth: format(formData.birthdate, 'yyyy/MM/dd'),
                        email: formData.email,
                        doctor_id: dataDoctor.id,
                        password: formData.password,
                        password_confirmation: formData.confirmpassword,
                        stripeToken: tokenId,
                        services: formData.service_id,
                        date_range_start: format(formData.date_range_start, 'yyyy/MM/dd'),
                        date_range_end: format(formData.date_range_end, 'yyyy/MM/dd'),
                        virtual_call: dataDoctor.accepts_phone_appointments === 'yes' || dataDoctor.accepts_phone_appointments === 'both' ? (1) : (0),
                        request_date: fechaDeHoy,
                        scheduled_datetime: format(formData.scheduled_datetime, 'yyyy/MM/dd HH:mm'),
                        original_cost: total,
                        final_cost: total,
                        amount_paid: total,
                        type: selectedOption,
                        patients: additionalFields,
                        email_doctor: dataDoctor.user.email,
                        comments:formData.comments,
                        cell_phone_number:countrycodes[nameCountry]?(`+${countrycodes[nameCountry]} ${formData.cell_phone_number} `):(formData.cell_phone_number),
                        city:formData.city,
                        country:formData.country,
                        user_id:formData.user_id
                    };

                }

                
            
                setSpinner(true);

                if(registeredPatient===true){

                    callApi2(elements, miObjeto);
                }else{

                   
                    callApi(elements, miObjeto);
                }


            }


        }


    };




    /*
    useEffect(() => {

        console.log("selectedOption ", selectedOption);
        let type = selectedOption === "client"?('kids'):('adult');

        const options4 = dataDoctor.services.filter(service => service.subspecialty.type === type).map(service => ({
            label: service.name,
            value: service.id,
            cost: service.cost.cost, 
            description:service.description
         }));

         setOptions3(options4);
    }, [selectedOption]);*/
 

    /*
    useEffect(() => {

        if(selectedOptionLogin === 'no'){
            setSelectedOption(selectType==='kids'?'client':'patient');
            let type = selectType;

            const options4 = dataDoctor.services.filter(service => service.subspecialty.type === type).map(service => ({
                label: service.name,
                value: service.id,
                cost: service.cost.cost, 
                description:service.description
             }));
    
             setOptions3(options4);

        }

    }, [selectedOptionLogin]);*/


    useEffect(() => {

    

            const options4 = dataDoctor.services.filter(service => service.subspecialty.type === selectType).map(service => ({
                label: service.name,
                value: service.id,
                cost: service.cost.cost, 
                description:service.description
             }));
    
             setOptions3(options4);

        

    }, [selectType]);

   //const [selectedOptionLogin, setSelectedOptionLogin] = useState('no');

   

    const closeModal = () =>{
        setRegisteredPatient(false);
        onHide();
        handleModalHide();
       

    }


    
    useEffect(() => {

        handleModalHide();

    }, [selectedOptionLogin]);


  

    return (
        <>

            <Modal
                show={show}
                onHide={() => closeModal()}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ Width: '9000px' }}
            >
                <Modal.Header>


                    <Modal.Title>MAKE APPOINTMENT</Modal.Title>
                    <Button variant="outline-danger" onClick={() => closeModal()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </Button>
                </Modal.Header>

                <Modal.Body>


                    <form action="#">
                        <div className="row">

                            <div style={{ marginBottom: 30 }} className="col-md-3">
                                <label>Already have an account?</label>
                                <div style={{ marginBottom: '1px' }}></div>
                                <span>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptionsLogin"
                                            id="inlineRadio3"
                                            value="yes"
                                            checked={selectedOptionLogin === 'yes'}
                                            onChange={handleRadioChangeLogin}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio3">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptionsLogin"
                                            id="inlineRadio4"
                                            value="no"
                                            checked={selectedOptionLogin === 'no'}
                                            onChange={handleRadioChangeLogin}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio4">
                                            No
                                        </label>
                                    </div>
                                </span>

                            </div>
                            <div className="col-md-12">
                                {selectedOptionLogin === 'yes' && (
                                    <FormLoginAppointment setBirthdays={setBirthdays} setFormData={setFormData} formData={formData} setRegisteredPatient={setRegisteredPatient} setAdditionalFields={setAdditionalFields} setSelectedOption={setSelectedOption}/>
                                )}
                            </div>


                            <div className="col-md-12">
                                <label>Is the appointment for you? </label>

                                <div style={{ marginBottom: '1px' }}></div>
                                <span>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio1"
                                            value="patient"
                                            checked={selectedOption === 'patient'}
                                            onChange={handleRadioChange}
                                            disabled
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio1">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio2"
                                            value="client"
                                            checked={selectedOption === 'client'}
                                            onChange={handleRadioChange}
                                            disabled
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio2">
                                            No
                                        </label>
                                    </div>
                                </span>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            



                            {selectedOption === 'patient' ? (
                                <>

                                    <div className="col-lg-6">
                                        <div className="checkbox-form">
                                            <h3>USER & PATIENT{registeredPatient===true?(<span  style={{ color: 'green' }}> information found</span>):(null)}</h3>
                                            <div className="row">


                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>First Name <span className="required">*</span></label>
                                                        <input type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.firstName}
                                                            onChange={handleInputChange}
                                                            disabled={registeredPatient}
                                                            style={{ textTransform: 'uppercase' }}
                                                            
                                                        />
                                                        {validationErrors.firstName && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.firstName}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Last Name <span className="required">*</span></label>
                                                        <input type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.lastName}
                                                            onChange={handleInputChange}
                                                            
                                                            disabled={registeredPatient}
                                                            style={{ textTransform: 'uppercase' }}
                                                        />

                                                        {validationErrors.lastName && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.lastName}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>

                                                {/*
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Birthdate<span className="required">*</span></label>
                                                        <DatePicker
                                                            placeholderText="MM/dd/yyyy"
                                                            selected={formData.birthdate}
                                                            onChange={(date) => handleInputChange({ target: { name: 'birthdate', value: date } })}
                                                            dateFormat="MM/dd/yyyy"
                                                            disabled={registeredPatient}
                                                         
                                                        />

                                                        {validationErrors.birthdate && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.birthdate}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>*/}

 
                                                {registeredPatient===true?(null):(
                                                <>
                                                <div className="col-md-6">
            <label>Birthdate</label>
            <div className="row" style={{ marginBottom: '-30px' }}>
                <div className="col-md-4">
                    <div className="checkout-form-list">
                        <label>Month<span className="required">*</span></label>
                        <input type="number"
                            style={{ width: '100%' }}
                            name="month"
                            id="month"
                           className="postform"
                            placeholder=""
                            value={month}
                            onChange={handleMonthChange}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="checkout-form-list">
                        <label>Day<span className="required">*</span></label>
                        <input type="number"
                            style={{ width: '100%' }}
                            name="day"
                            id="day"
                            className="postform"
                            placeholder=""
                            value={day}
                            onChange={handleDayChange}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="checkout-form-list">
                        <label>Year<span className="required">*</span></label>
                        <input type="number"
                            style={{ width: '100%' }}
                            name="year"
                            id="year"
                            className="postform"
                            placeholder=""
                            value={year}
                            onChange={handleYearChange}
                            disabled={false}
                            min={1914}
                        />
                    </div>
                </div>
            </div>
            {validationErrors.birthdate && (
                <div  className="badge bg-danger text-wrap">
                    {validationErrors.birthdate}
                </div>
            )}
        </div>

                                                
                                                
                                                
                                                
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Email<span className="required">*</span></label>
                                                        <input type="email"
                                                            name="email" id="email"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.email}
                                                            onChange={handleInputChange}


                                                        />

                                                        {validationErrors.email && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.email}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Confirm Email<span className="required">*</span></label>
                                                        <input type="email"
                                                            name="confirmemail" id="confirmemail"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.confirmemail}
                                                            onChange={handleInputChange}


                                                        />

                                                        {validationErrors.confirmemail && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.confirmemail}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>


                                            
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Country<span className="required">*</span></label>
                                                        <select style={{ height: '45px' }} onChange={handleCountryChange} className="form-select select_style">
                                                            {arrayCountries.map((country) => (
                                                                <option key={country.geonameId} value={JSON.stringify({ geonameId: country.geonameId, countryName: country.countryName })}>
                                                                    {country.countryName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {validationErrors.country && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.country}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>State/Province<span className="required">*</span></label>
                                                        <select style={{ height: '45px' }} onChange={handleCityChange} className="form-select select_style">
                                                            {arrayCities.map((city) => (
                                                                <option key={city.geonameId} value={city.name}>
                                                                    {city.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {validationErrors.city && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.city}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="checkout-form-list">
                                                        <label>code<span className="required">*</span></label>
                                                        <input type="text"
                                                            name="code"
                                                            id="code"
                                                            className="postform"
                                                            placeholder=""
                                                            value={countrycodes[nameCountry]?(`+ ${countrycodes[nameCountry]}`):(null)}
                                                           
                                                            disabled={true}
                                                        />

                                         


                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="checkout-form-list">
                                                        <label>Phone<span className="required">*</span></label>
                                                        <input type="text"
                                                            name="cell_phone_number"
                                                            id="cell_phone_number"
                                                            className="postform"
                                                            placeholder=""
                                                            onChange={handleInputChange}
                                                            disabled={makeAppointment === false ? (false) : (true)}
                                                        />
                                                         {validationErrors.cell_phone_number && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.cell_phone_number}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Password<span className="required">*</span></label>
                                                        <input 
                                                            type={showPassword2 ? "text" : "password"}
                                                            placeholder=""
                                                            value={formData.password}
                                                            onChange={handleInputChange}
                                                            name="password"

                                                        />
                                                        <button 
                                                            type="button" 
                                                            onClick={togglePasswordVisibility2}
                                                            style={{
                                                                marginLeft: '10px',
                                                                cursor: 'pointer',
                                                                border: 'none',
                                                                background: 'none',
                                                                color: 'blue',
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            {showPassword2 ? "Hide" : "Show"}
                                                        </button>
                                                        {validationErrors.password && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.password}
                                                            </div>
                                                        )}
                                                        {formData.confirmpassword === formData.password?(
                                                           null     
                                                        ):(

                                                            formData.password.length >= 8?(
                                                                <div className="badge bg-danger text-wrap">
                                                                    Passwords do not match.
                                                                </div>
                                                             ):(
                                                                <div className="badge bg-danger text-wrap">
                                                                   Password must be at least 8 characters long.
                                                                </div>

                                                             )
                                                            
                                                        )}
                                                        

                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Confirm Password<span className="required">*</span></label>
                                                        <input 
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder=""
                                                            value={formData.confirmpassword}
                                                            onChange={handleInputChange}
                                                            name="confirmpassword"

                                                        />
                                                        <button 
                                                            type="button" 
                                                            onClick={togglePasswordVisibility}
                                                            style={{
                                                                marginLeft: '10px',
                                                                cursor: 'pointer',
                                                                border: 'none',
                                                                background: 'none',
                                                                color: 'blue',
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            {showPassword ? "Hide" : "Show"}
                                                        </button>

                                                        {validationErrors.confirmpassword && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.confirmpassword}
                                                            </div>
                                                        )}

                                                        {formData.confirmpassword === formData.password?(
                                                           null     
                                                        ):(

                                                            <div className="badge bg-danger text-wrap">
                                                               Passwords do not match.
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                                </>
                                                )}
                                                <div className="col-md-6">
                                                    <CustomDatePicker


                                                        startDate={formData.date_range_start}
                                                        endDate={formData.date_range_end}
                                                        onChange={handleDateChange}
                                                        makeAppointment={makeAppointment}
                                                        
                                                    />
                                                    {validationErrors.date_range_start && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors.date_range_start}
                                                        </div>
                                                    )}
                                                    {validationErrors.date_range_end && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors.date_range_end}
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Preferred day and time
                                                            <span className="required">*</span></label>
                                                        <DatePicker
                                                            selected={formData.scheduled_datetime}
                                                            onChange={(date) => handleDateChange2(date)}
                                                            dateFormat="MM/dd/yyyy hh:mm aa"  // Añade el formato de la hora
                                                            showTimeInput  // Habilita la entrada de tiempo
                                                            timeInputLabel="Time:"  // Etiqueta para la entrada de tiempo
                                                            timeFormat="HH:mm"  // Formato de la hora
                                                            timeIntervals={15}  // Intervalo de minutos para la selección de tiempo
                                                            disabled={makeAppointment === false ? (false) : (true)}
                                                            minDate={formData.date_range_start} 
                                                            maxDate={formData.date_range_end}
                                                        />
                                                        {validationErrors.scheduled_datetime && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.scheduled_datetime}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {formData.city == '' && formData.country === '' && registeredPatient === false?(


                                                <div className="col-md-12">
                                                    <div className="country-select">
                                                        <label>Service<span className="required">*</span></label>                                                        
                                                        <div className="badge bg-danger text-wrap">
                                                            Choose a city to show you the services.
                                                        </div>
                                                    </div>
                                                </div>                                                    

                                                ):(   
                                                        <div className="col-md-12">
                                                            <div className="country-select">
                                                                <label>Service<span className="required">*</span></label>
                                                                <MultiSelect
                                                                    options={options3}
                                                                    value={selectedOptions}
                                                                    onChange={handleSelectedChange}
                                                                    labelledBy="Select service"
                                                                    className="w-full md:w-20rem custom-multi-select"
                                                                    id="ms"
                                                                    name="ms"
                                                                />
                                                                
                                                                {validationErrors.service_id && (
                                                                    <div className="badge bg-danger text-wrap">
                                                                        {validationErrors.service_id}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                )}


                                                {/*
                                    <div className="col-md-12">
                                            <div className="country-select">
                                                <label>Service<span className="required">*</span></label>
                                                <select
                                                
                                                onChange={(e) => handleChange3(e.target.value)}   
                                                value={formData.service_id}
                                          
                                                >
                                                 <option value="" disabled>Select a service</option>    
                                                {dataDoctor.services.map(service => (
                                                   <option key={service.id} value={service.id}>
                                                      {service.name}
                                                   </option>
                                                   ))}
                                                </select>

                                                {validationErrors.service_id && (
                                                    <div className="badge bg-danger text-wrap">
                                                    {validationErrors.service_id}
                                                    </div>
                                                )}

                                            </div>
                                    </div>*/}

                                    {/*
                                    <div className="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input"
                                                            type="checkbox"
                                                            id="flexCheckIndeterminate2"
                                                            onChange={handleCheckboxChange3}


                                                        />

                                                        <label class="form-check-label" for="flexCheckIndeterminate">

                                                            {terms == false ? (<a> I accept Waiver, Terms & Conditions, and Aviso de privacidad</a>) : (<a href="/terms" target="_blank" style={{ color: 'blue' }}> I accept Waiver, Terms & Conditions, and Aviso de privacidad</a>)

                                                            }
                                                        </label>

                                                    </div>
                                        </div>
                                        
                                       <div className="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input"
                                                            type="checkbox"
                                                            id="flexCheckIndeterminate2"
                                                            onChange={handleCheckboxChange4}


                                                        />

                                                        <label class="form-check-label" for="flexCheckIndeterminate">

                                                            {terms2 == false ? (<a > I am aware of the Cancellation Policy. </a>) : (<a href="/terms" target="_blank" style={{ color: 'blue' }}>I am aware of the Cancellation Policy.</a>)

                                                            }
                                                        </label>

                                                    </div>
                                        </div>*/}

                                                {/* <div className="col-md-6">
                                        <div class="form-check">
                                            <input class="form-check-input" 
                                            type="checkbox" 
                                           
                                            id="flexCheckIndeterminate"
                                        
                                            checked={dataDoctor.accepts_phone_appointments}
                                            onChange={handleCheckboxChange}
                                            disabled={true}
                                                         
                                            />
                       
                       

                                            <label class="form-check-label" for="flexCheckIndeterminate">
                                                Virtual Call
                                            </label>

                                          
                                        </div>
                                    </div> */}

                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-lg-6">
                                        <div className="checkbox-form">
                                            <h3>Specialist</h3>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Specialist's Name <span className="required">*</span></label>
                                                        <input type="text" placeholder="" value={`${dataDoctor.first_name} ${dataDoctor.last_name}`} readOnly />
                                                    </div>
                                                </div>



                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Specialty<span className="required">*</span></label>
                                                        <input type="text" placeholder="" value={dataDoctor.services[0].subspecialty.specialty.name} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Subspecialty<span className="required">*</span></label>
                                                        <input type="text" placeholder="" value={dataDoctor.services[0].subspecialty.name} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Comments</label>
                                                        <textarea 
                                                        
                                                        name="comments" 
                                                        id="comments" 
                                                        cols="30" 
                                                        rows="10"
                                                        value={formData.comments}
                                                        onChange={handleInputChange}
                                                        placeholder="Your Comments...."></textarea>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </>
                            ) : (

                                <>


                                    <div className="col-lg-6">
                                        <div className="checkbox-form">
                                            <h3> User{registeredPatient===true?(<span  style={{ color: 'green' }}> information found</span>):(null)}</h3>
                                            <div className="row">


                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>First Name<span className="required">*</span></label>
                                                        <input type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.firstName}
                                                            onChange={handleInputChange}
                                                            disabled={registeredPatient}
                                                            style={{ textTransform: 'uppercase' }}

                                                        />
                                                        {validationErrors.firstName && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.firstName}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Last Name <span className="required">*</span></label>
                                                        <input type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.lastName}
                                                            onChange={handleInputChange}
                                                            
                                                            disabled={registeredPatient}
                                                            style={{ textTransform: 'uppercase' }}
                                                        />

                                                        {validationErrors.lastName && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.lastName}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>

                                    {registeredPatient===false?(

                                            <>
 <div className="col-md-6">
            <label>Birthdate</label>
            <div className="row" style={{ marginBottom: '-30px' }}>
                <div className="col-md-4">
                    <div className="checkout-form-list">
                        <label>Month<span className="required">*</span></label>
                        <input type="number"
                            style={{ width: '100%' }}
                            name="month"
                            id="month"
                           className="postform"
                            placeholder=""
                            value={month}
                            onChange={handleMonthChange}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="checkout-form-list">
                        <label>Day<span className="required">*</span></label>
                        <input type="number"
                            style={{ width: '100%' }}
                            name="day"
                            id="day"
                            className="postform"
                            placeholder=""
                            value={day}
                            onChange={handleDayChange}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="checkout-form-list">
                        <label>Year<span className="required">*</span></label>
                        <input type="number"
                            style={{ width: '100%' }}
                            name="year"
                            id="year"
                            className="postform"
                            placeholder=""
                            value={year}
                            onChange={handleYearChange}
                            disabled={false}
                            min={1914}
                        />
                    </div>
                </div>
            </div>
            {validationErrors.birthdate && (
                <div style={{ marginTop: '-200px', height:'15px' }} className="badge bg-danger text-wrap">
                    {validationErrors.birthdate}
                </div>
            )}
        </div>



                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Email<span className="required">*</span></label>
                                                        <input type="email"
                                                            name="email" id="email"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.email}
                                                            onChange={handleInputChange}


                                                        />

                                                        {validationErrors.email && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.email}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Confirm Email<span className="required">*</span></label>
                                                        <input type="email"
                                                            name="confirmemail" id="confirmemail"
                                                            className="postform"
                                                            placeholder=""
                                                            value={formData.confirmemail}
                                                            onChange={handleInputChange}


                                                        />

                                                        {validationErrors.confirmemail && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.confirmemail}
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>



                                            
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Country<span className="required">*</span></label>
                                                        <select style={{ height: '45px' }} onChange={handleCountryChange} className="form-select select_style">
                                                            {arrayCountries.map((country) => (
                                                                <option key={country.geonameId} value={JSON.stringify({ geonameId: country.geonameId, countryName: country.countryName })}>
                                                                    {country.countryName}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        {validationErrors.country && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.country}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>State/Province<span className="required">*</span></label>
                                                        <select style={{ height: '45px' }} onChange={handleCityChange} className="form-select select_style">
                                                            {arrayCities.map((city) => (
                                                                <option key={city.geonameId} value={city.name}>
                                                                    {city.name}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        {validationErrors.city && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.city}
                                                            </div>
                                                        )}
                                                        
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="checkout-form-list">
                                                        <label>code<span className="required">*</span></label>
                                                        <input type="text"
                                                            name="code"
                                                            id="code"
                                                            className="postform"
                                                            placeholder=""
                                                            value={countrycodes[nameCountry]?(`+ ${countrycodes[nameCountry]}`):(null)}
                                                           
                                                            disabled={true}
                                                        />

                                         


                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="checkout-form-list">
                                                        <label>Phone<span className="required">*</span></label>
                                                        <input type="text"
                                                            name="cell_phone_number"
                                                            id="cell_phone_number"
                                                            className="postform"
                                                            placeholder=""
                                                            onChange={handleInputChange}
                                                            disabled={makeAppointment === false ? (false) : (true)}
                                                        />
                                                        {validationErrors.cell_phone_number && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.cell_phone_number}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                
                                                
                                                


                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Password<span className="required">*</span></label>
                                                        <input 
                                                            type={showPassword2 ? "text" : "password"}
                                                            placeholder=""
                                                            value={formData.password}
                                                            onChange={handleInputChange}
                                                            name="password"

                                                        />
                                                        <button 
                                                            type="button" 
                                                            onClick={togglePasswordVisibility2}
                                                            style={{
                                                                marginLeft: '10px',
                                                                cursor: 'pointer',
                                                                border: 'none',
                                                                background: 'none',
                                                                color: 'blue',
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            {showPassword2 ? "Hide" : "Show"}
                                                        </button>

                                                        {validationErrors.password && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.password}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Confirm Password<span className="required">*</span></label>
                                                        <input 
                                                            
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder=""
                                                            value={formData.confirmpassword}
                                                            onChange={handleInputChange}
                                                            name="confirmpassword"

                                                        />
                                                        <button 
                                                            type="button" 
                                                            onClick={togglePasswordVisibility}
                                                            style={{
                                                                marginLeft: '10px',
                                                                cursor: 'pointer',
                                                                border: 'none',
                                                                background: 'none',
                                                                color: 'blue',
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            {showPassword ? "Hide" : "Show"}
                                                        </button>

                                                        {validationErrors.confirmpassword && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.confirmpassword}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                                </>
                                                ):(null)}
                                                <div className="col-md-6">
                                                    <CustomDatePicker


                                                        startDate={formData.date_range_start}
                                                        endDate={formData.date_range_end}
                                                        onChange={handleDateChange}
                                                        makeAppointment={makeAppointment}
                                                        
                                                    />
                                                    {validationErrors.date_range_start && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors.date_range_start}
                                                        </div>
                                                    )}
                                                    {validationErrors.date_range_end && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors.date_range_end}
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="checkout-form-list">
                                                        <label>Preferred day and time<span className="required">*</span></label>
                                                        <DatePicker
                                                            selected={formData.scheduled_datetime}
                                                            onChange={(date) => handleDateChange2(date)}
                                                            dateFormat="MM/dd/yyyy hh:mm aa"  // Añade el formato de la hora
                                                            showTimeInput  // Habilita la entrada de tiempo
                                                            timeInputLabel="Time:"  // Etiqueta para la entrada de tiempo
                                                            timeFormat="HH:mm"  // Formato de la hora
                                                            timeIntervals={15}  // Intervalo de minutos para la selección de tiempo
                                                            disabled={makeAppointment === false ? (false) : (true)}
                                                            minDate={formData.date_range_start} 
                                                            maxDate={formData.date_range_end}
                                                        />
                                                        {validationErrors.scheduled_datetime && (
                                                            <div className="badge bg-danger text-wrap">
                                                                {validationErrors.scheduled_datetime}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>



                                                {/* 

                                    <div className="col-md-12">
                                            <div className="country-select">
                                                <label>Service<span className="required">*</span></label>
                                                <select
                                                
                                                onChange={(e) => handleChange3(e.target.value)}   
                                                value={formData.service_id}
                                          
                                                >
                                                 <option value="" disabled>Select a service</option>    
                                                {dataDoctor.services.map(service => (
                                                   <option key={service.id} value={service.id}>
                                                      {service.name}
                                                   </option>
                                                   ))}
                                                </select>

                                                {validationErrors.service_id && (
                                                    <div className="badge bg-danger text-wrap">
                                                    {validationErrors.service_id}
                                                    </div>
                                                )}

                                            </div>
                                                </div>*/}



                                          




                                           {/*
                                                <div className="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input"
                                                            type="checkbox"

                                                            id="flexCheckIndeterminate"

                                                            checked={dataDoctor.accepts_phone_appointments}
                                                            onChange={handleCheckboxChange}
                                                            disabled={true}

                                                        />

                                                        
                                            <label class="form-check-label" for="flexCheckIndeterminate">
                                                Virtual Call
                                            </label>


                                                    </div>
                                                </div>*/}

                                            </div>
                                        </div>

                                    </div>



                                    <div className="col-lg-6">
                                        <div className="checkbox-form">
                                            <h3>Specialist</h3>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Specialist's Name <span className="required">*</span></label>
                                                        <input type="text" placeholder="" value={`${dataDoctor.first_name} ${dataDoctor.last_name}`} readOnly />
                                                    </div>
                                                </div>


                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Specialty<span className="required">*</span></label>
                                                        <input type="text" placeholder="" value={dataDoctor.services[0].subspecialty.specialty.name} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Subspecialty<span className="required">*</span></label>
                                                        <input type="text" placeholder="" value={dataDoctor.services[0].subspecialty.name} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="checkout-form-list">
                                                        <label>Comments</label>
                                                        <textarea 
                                                        
                                                        name="comments" 
                                                        id="comments" 
                                                        cols="30" 
                                                        rows="10"
                                                        value={formData.comments}
                                                        onChange={handleInputChange}
                                                        placeholder="Your Comments...."></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-lg-12">
                                        <div className="checkbox-form">
                                            <h3>Patients</h3>
                                        </div>
                                    </div>


                                    {additionalFields.map((field, index) => (

                                        <>
                                            <div className="separator"></div>

                                            <div className="col-md-3">
                                                <div className="checkout-form-list">
                                                    <label>First Name<span className="required">*</span></label>
                                                    <br/>
                                                    <input type="text"

                                                        name={`firstName-${index}`}
                                                        id={`firstName-${index}`}
                                                        className="postform"

                                                        disabled={!field.user_id?false:true}

                                                        value={field.first_name}
                                                        onChange={(event) => handleInputChange2(event, index, "first_name")}
                                                        style={{ textTransform: 'uppercase' }}
                                                    />

                                                    {validationErrors2[`firstName-${index}`] && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors2[`firstName-${index}`]}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="checkout-form-list">
                                                    
                                                    <label>Last Name <span className="required">*</span></label>
                                                   <br/>
                                                    <input type="text"

                                                        name={`lastName-${index}`}
                                                        id={`lastName-${index}`}
                                                        className="postform"
                                                        placeholder=""
                                                        value={field.last_name}
                                                        onChange={(event) => handleInputChange2(event, index, "last_name")}
                                                        disabled={!field.user_id?false:true}
                                                        style={{ textTransform: 'uppercase' }}
                                                    />

                                                    {validationErrors2[`lastName-${index}`] && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors2[`lastName-${index}`]}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                            
    
        {/* Aquí va el código existente de tu formulario dinámico */}

{!field.user_id? (   
  <>     
  <div className="col-md-3">
      <label style={{ marginBottom: '17px' }}>Birthdate</label>
      <div className="row">

          <div className="col-md-4">
              <div className="checkout-form-list">
                  <label>Month<span className="required">*</span></label>
                  <input
                      type="number"
                      style={{ width: '100%' }}
                      name={`month-${index}`}
                      id={`month-${index}`}
                      className="postform"
                      placeholder=""
                      value={birthdays[index]?.month}
                      onChange={(event) => handleMonthChange2(event, index)}
                      disabled={!field.user_id?false:true}
                      />
              </div>
          </div>
          <div className="col-md-4">
              <div className="checkout-form-list">
                  <label>Day<span className="required">*</span></label>
                  <input
                      type="number"
                      style={{ width: '100%' }}
                      name={`day-${index}`}
                      id={`day-${index}`}
                      className="postform"
                      placeholder=""
                      value={birthdays[index]?.day}
                      onChange={(event) => handleDayChange2(event, index)}
                      disabled={!field.user_id?false:true}
                      />
              </div>
          </div>

          
          <div className="col-md-4">
              <div className="checkout-form-list">
                  <label>Year<span className="required">*</span></label>
                  <input
                      type="number"
                      style={{ width: '100%' }}
                      name={`year-${index}`}
                      id={`year-${index}`}
                      className="postform"
                      placeholder=""
                      value={birthdays[index]?.year}
                      onChange={(event) => handleYearChange2(event, index)}
                      disabled={!field.user_id?false:true}
                      min={selectType==='kids'?(año18AnosAtras):(1914)}
                      max={selectType==='kids'?(maxfechaHoy):(año18AnosAtras)}
                  />
              </div>
          </div>
          {validationErrors2[`birthdate-${index}`] && (
          <div style={{ marginTop: '-25px', height:'15px' }} className="badge bg-danger text-wrap">
              {validationErrors2[`birthdate-${index}`]}
          </div>
         )}
      </div>
</div> 

</>
):( 
    
    <div className="col-md-2">
    <div className="checkout-form-list">
        <label>Birthdate <span className="required">*</span></label>
        <br/>
        <DatePicker

            placeholderText="MM/dd/yyyy"
            selected={field.view_birth}
            onChange={(date) => handleInputChange2(date, index, "date_of_birth")}

            disabled={!field.user_id?false:true}
            />

        {validationErrors2[`birthdate-${index}`] && (
            <div className="badge bg-danger text-wrap">
                {validationErrors2[`birthdate-${index}`]}
            </div>
        )}
    </div>
</div>
    
    
)}
       

                                            
                                            <br/>

                                          
                                            {formData.city === '' && formData.country === '' && registeredPatient === false  ?(
                                            <div className="col-md-3">
                                                <div className="country-select">
                                                    <label>Service<span className="required">*</span></label>
                                                    <br/>
                                                    <br/>
                                                    <div className="badge bg-danger text-wrap">
                                                        Choose a city to show you the services.
                                                    </div>
                                                </div>
                                            </div>    
                                                    
                                            ):(                                            
                                            <div className="col-md-3">
                                                <div className="country-select">
                                                    <label>Service<span className="required">*</span></label>
                                                    <br/>
                                                    <MultiSelect
                                                        options={options3}
                                                        value={field.services_2}
                                                        onChange={(event) => handleInputChange2(event, index, "services")}
                                                        labelledBy="Select service"
                                                        className="w-full md:w-20rem custom-multi-select"
                                                        id="ms1"
                                                        name="ms"
                                                    />

                                                    {validationErrors2[`services-${index}`] && (
                                                        <div className="badge bg-danger text-wrap">
                                                            {validationErrors2[`services-${index}`]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            )}



                                            {additionalFields.length > 1 ?

                                                (
                                                    <>
                                                        <div className="col-md-2" >

                                                            <Button variant="outline-danger" onClick={() => removeField(index)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                </svg>
                                                            </Button>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </>

                                                ) :


                                                (

                                                    null


                                                )}


                                        </>



                                    ))}



                                  
                                    <br />
                                                                   
                                        <div className="col-md-8">
                                            <Button variant="outline-primary" size="sm" onClick={() => addField()}>
                                                Add Another Patient
                                            </Button>
                                        </div>
                                

                                </>


                            )}



                        </div>
                        <br />


                        <div className="row">


                            <Elements stripe={stripePromise}>

                                <PaymentForm handleSubmit2={handleSubmit}
                                    setStripeToken={setStripeToken}
                                    stripeToken={stripeToken}
                                    formData={formData}
                                    service={selectedOption === 'client' ? (additionalFields) : (selectedOptions)}
                                    selectedOption={selectedOption}
                                    setTotal={setTotal}
                                    total={total}
                                    spinner={spinner}
                                    handleCheckboxChange3={handleCheckboxChange3}
                                    terms={terms}
                                    handleCheckboxChange4={handleCheckboxChange4}
                                    terms2={terms2}
                                />

                            </Elements>



                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </>
    );
};

export default ModalCreate;